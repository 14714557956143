<section>
  <geaui-page-header
    [withSidebar]="true"
    [pageTitle]="ticketDetailTitle"
    [isLoading]="loading"
    (backArrowClicked)="navigateBack()">
    <div class="icon-container">
      <ng-container *ngIf="ticket?.statusCode === StatusCode.COMPLETED">
        <div
          class="suffix-icon-container"
          *ngIf="!loading; else iconLoading"
          (click)="addTicket(ticket?.id)"
          data-cy="suffix-icon">
          <geaui-icon-v2 class="icon" [file]="successorTicketIcon.file"></geaui-icon-v2>
          <div class="icon-label gea-font-subline-16">{{ successorTicketIcon?.label ?? '' | translate }}</div>
        </div>
      </ng-container>

      <div
        class="suffix-icon-container"
        *ngIf="!loading && !attachmentsLoading; else iconLoading"
        (click)="toggleAttachmentView()"
        data-cy="suffix-icon">
        <geaui-icon-v2 class="icon" [file]="attachmentsIcon.file"></geaui-icon-v2>
        <div class="icon-label gea-font-subline-16">{{ attachmentsIcon?.label ?? '' | translate }}</div>
      </div>
    </div>
  </geaui-page-header>
  <geaui-attachments-v2
    *ngIf="!attachmentsLoading"
    [loading]="loading || isUploadingFile"
    [disableUpload]="ticket?.statusCode === StatusCode.COMPLETED"
    [showAttachmentView]="showAttachmentSideModal"
    [savedFiles]="existingAttachments"
    [hasPermission]="hasUpdatePermission && ticket.editable"
    [files]="uploadFailedAttachments"
    [maxFileSize]="attachmentsService.maxFileSizeInBytes"
    [creatingUser]="'TICKETS.DETAIL.ATTACHMENT-UPLOADED-CUSTOMER' | translate"
    [isFileRowLoading]="isFileRowLoading"
    (filesChanged)="filesChanged($event)"
    (closeAttachmentView)="closeAttachmentView()"
    (saveFilesClicked)="uploadFiles($event)"
    (downloadAttachmentClicked)="downloadAttachment($event)">
  </geaui-attachments-v2>
  <geaui-side-modal-v2 [modalTitle]="'Description'" [show]="showDescriptionPanel" (closeSideModal)="showDescriptionPanel = false">
    <ng-container side-modal-body>
      <p class="gea-font-description-16" [innerHTML]="ticket?.formattedDescription"></p>
    </ng-container>
  </geaui-side-modal-v2>

  <div class="geaui-grid geaui-sidebar-grid">
    <div
      class="sidebar-container geaui-col-desktop-xl-3 geaui-col-desktop-xl-start-7 geaui-col-desktop-lg-3 geaui-col-desktop-md-3 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4">
      <advance-related-tickets [loading]="loading" [ticket]="ticket" (clickTicket)="openTicket($event)"></advance-related-tickets>

      <div class="info-container">
        <advance-info-box [isLoading]="loading" pageTitle="TICKETS.DETAIL.REQUESTED-DATE">
          {{ ticket?.creationDate ?? '' | userTimezoneDate: 'shortDate' }}
        </advance-info-box>

        <advance-info-box [isLoading]="loading" pageTitle="TICKETS.DETAIL.EQUIPMENT">
          {{ ticket?.mainProductDescription }}
        </advance-info-box>

        <advance-info-box [isLoading]="loading" pageTitle="TICKETS.CREATE.CATEGORY.TITLE">
          {{ 'TICKETS.SERVICE-CATEGORY.' + ticket?.serviceCategory | translate }}
        </advance-info-box>

        <advance-info-box [isLoading]="loading" pageTitle="TICKETS.DETAIL.STATUS">
          <advance-status [status]="ticket?.statusCode ?? 'default'"></advance-status>
        </advance-info-box>

        <advance-info-box [isLoading]="loading" pageTitle="TICKETS.DETAIL.LAST-UPDATE">
          {{ ticket?.lastChangeDateTime ?? '' | userTimezoneDate: 'medium' }}
        </advance-info-box>

        <advance-info-box
          [isLoading]="loading"
          [hasDivider]="false"
          pageTitle="TICKETS.DETAIL.REQUESTED-BY"
          data-cy="ticket-requested-by">
          {{ ticket?.createdByName ?? '-' }}
        </advance-info-box>
      </div>

      <advance-related-service-logs
        [relatedServiceActivities]="relatedServiceLogActivities"
        [loading]="relatedServiceLogsLoading"></advance-related-service-logs>
    </div>

    <div
      class="geaui-col-desktop-xl-9 geaui-col-desktop-xl-start-10 geaui-col-desktop-lg-9 geaui-col-desktop-md-9 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4">
      <div class="geaui-sub-grid max-height">
        <div
          class="description-container geaui-col-desktop-xl-24 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
          *ngIf="!loading; else descriptionLoading"
          data-cy="ticket-description-container">
          <div class="description-header">
            <span class="gea-font-heading-16">{{ 'TICKETS.DETAIL.DESCRIPTION' | translate }}</span>
            <geaui-link-v2 *ngIf="showSeeMoreLink()" (click)="showDescriptionPanel = !showDescriptionPanel" data-cy="show-more">
              {{ 'TICKETS.DETAIL.DESCRIPTION-SEE-MORE' | translate }}
            </geaui-link-v2>
          </div>
          <div class="gea-font-description-16 description-text" data-cy="text">{{ ticket?.description }}</div>
        </div>

        <advance-comment
          class="max-height border geaui-col-desktop-xl-24 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
          [loadingForm]="loading || conversationsLoading"
          [interactions]="interactions"
          [ticket]="ticket"
          (openAttachmentView)="toggleAttachmentView()">
        </advance-comment>
      </div>
    </div>
  </div>
</section>

<ng-template #descriptionLoading>
  <div
    class="description-container geaui-col-desktop-xl-24 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4">
    <div class="description-header">
      <ngx-skeleton-loader [theme]="{ height: '20px', width: '100px' }" count="1" appearance="line"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ height: '20px', width: '80px' }" count="1" appearance="line"></ngx-skeleton-loader>
    </div>
    <ngx-skeleton-loader [theme]="{ height: '45px', width: '100%' }" count="1" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #iconLoading>
  <ngx-skeleton-loader
    [theme]="{ height: '30px', width: '100px', margin: '0 25px' }"
    count="1"
    appearance="line"></ngx-skeleton-loader>
</ng-template>
