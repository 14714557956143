import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TicketDetails } from '../../models/ticket-list-entry.model';
import { RelatedTicket } from '../../../api/v1';

@Component({
  selector: 'advance-related-tickets',
  templateUrl: './related-tickets.component.html',
  styleUrls: ['./related-tickets.component.scss'],
})
export class RelatedTicketsComponent {
  @Input() loading = false;
  @Input() set ticket(value: TicketDetails) {
    this._ticket = value;

    this.hasPredecessorTicket = !!value?.predecessorTicket;
    this.hasSuccessorTickets = (value?.successorTickets?.length ?? 0) > 0;
    this.predecessorTicket = value.predecessorTicket;
    this.successorTickets = value?.successorTickets;
    this.showPredecessorSuccessorOverlay = false;
  }

  @Output() public clickTicket = new EventEmitter<string>();

  _ticket!: TicketDetails;
  hasPredecessorTicket = false;
  hasSuccessorTickets = false;
  predecessorTicket?: RelatedTicket;
  successorTickets?: RelatedTicket[];
  showPredecessorSuccessorOverlay = false;

  handleClickTicket(ticketId: string) {
    this.showPredecessorSuccessorOverlay = false;
    this.clickTicket.emit(ticketId);
  }

  toggleOverlay() {
    this.showPredecessorSuccessorOverlay = !this.showPredecessorSuccessorOverlay;
  }
}
