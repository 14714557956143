<geaui-page-layout [headerTemplate]="header" [contentTemplate]="content" [sidebarTemplate]="sidebar">
  <ng-template #header>
    <geaui-header
      [noAuth]="noAuth"
      [googleAnalyticsActivated]="true"
      [showBanner]="false"
      headerTitle="Support App"
      data-cy="header">
    </geaui-header>
  </ng-template>
  <ng-template #content>
    <div class="spinner" *ngIf="(!tokenReady || !userReady) && !loggedOutPageReady">
      <p-progressSpinner animationDuration="2s" strokeWidth="4"></p-progressSpinner>
    </div>

    <div class="content-body">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
  <ng-template #sidebar>
    @if (!noAuth && tokenReady && featureFlagsReady) {
      <geaui-sidebar-v2
        [navigationItems]="navItems"
        [adminViewNavigationItems]="adminNavItems"
        [slimView]="true"
        [bottomNavigation]="botItems"
        [devisionIconPath]="environment.appContentStorageURL + 'core/support/icons/24px_support-app.svg'"
        devisionIconBackgroundColor="var(--geaui-energetic-pink-130)"
        devisionName="Support App"
        data-cy="sidebar">
      </geaui-sidebar-v2>
    }
  </ng-template>
</geaui-page-layout>
