/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateTicketDateFilter { 
    organizationId: string;
    createdBefore: string;
    operation: UpdateTicketDateFilter.OperationEnum;
}
export namespace UpdateTicketDateFilter {
    export type OperationEnum = 'UPDATE' | 'DELETE' | 'CREATE';
    export const OperationEnum = {
        Update: 'UPDATE' as OperationEnum,
        Delete: 'DELETE' as OperationEnum,
        Create: 'CREATE' as OperationEnum
    };
}


