/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiError { 
    errorCode?: string;
    timestamp: string;
    status: string;
    error: string;
    message: string;
    path: string;
    traceId?: string;
    spanId?: string;
    errorParams?: object;
}

