import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { DealerInner, SupportApiService } from '../../api/v1';

@Injectable({
  providedIn: 'root',
})
export class DealerService {
  constructor(private api: SupportApiService) {}

  getDealer(organizationId: string): Observable<DealerInner[]> {
    return this.api.getDealer(organizationId);
  }
}
