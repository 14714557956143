import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule, HEADER_CONFIG } from './app/app.module';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from './environments/models/environment.model';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

void fetch('./assets/environment-config.json')
  .then((resp) => resp.json())
  .then((config: EnvironmentConfiguration) => {
    platformBrowserDynamic([
      { provide: ENVIRONMENT_CONFIG, useValue: config },
      {
        provide: HEADER_CONFIG,
        useValue: {
          authConfigUrls:
            config.support.baseURL || config.asset.baseURL
              ? new Map([
                  ['http://localhost:8081/*', ['openid', 'offline_access', 'email', config.oAuth.clientId]],
                  ['http://localhost:8082/*', ['openid', 'offline_access', 'email', config.oAuth.clientId]],
                ])
              : [],
          redirectUri: config.oAuth.redirectURL,
          postLogoutRedirectUri: config.oAuth.postLogoutRedirectURL,
          clientId: config.oAuth.clientId,
          authority: config.oAuth.authority,
          authorityDomain: config.oAuth.authorityDomain,
          portalURL: config.portal.redirectURL,
        },
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  });
