<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header [withSidebar]="true" [hasBackArrow]="false" pageTitle="REQUEST-VIEW-SETTINGS.TITLE"> </geaui-page-header>
  </ng-container>
  <ng-container page-content>
    <div class="geaui-grid geaui-sidebar-grid">
      <div class="sub-header-container geaui-col-desktop-md-8">
        <span>{{ 'REQUEST-VIEW-SETTINGS.SUB-TITLE' | translate }}</span>
        <geaui-button-v2
          [pTooltip]="'REQUEST-VIEW-SETTINGS.NO-MORE-ORGAS' | translate"
          [tooltipDisabled]="
            (orgasFiltered && userOrganizations.length > 0) || (!orgasFiltered && userOrganizations.length > settingsList.length)
          "
          [type]="ButtonType.SECONDARY"
          [loading]="formLoading"
          [disabled]="
            sending ||
            (orgasFiltered && !userOrganizations.length) ||
            (!orgasFiltered && userOrganizations.length <= settingsList.length)
          "
          (click)="addEntry()"
          preIcon="e-add">
          {{ 'X.BUTTON.ADD' | translate }}
        </geaui-button-v2>
      </div>
      <div class="list-container geaui-sub-grid geaui-col-desktop-md-8" [formGroup]="form">
        <span class="bold-header geaui-col-desktop-md-1">#</span>
        <span class="bold-header geaui-col-desktop-md-4">{{ 'REQUEST-VIEW-SETTINGS.ORGANIZATION' | translate }}</span>
        <span class="bold-header geaui-col-desktop-md-4">{{ 'REQUEST-VIEW-SETTINGS.REQUESTS-FROM' | translate }}</span>
        <ngx-skeleton-loader
          class="geaui-col-desktop-md-1 geaui-col-desktop-md-start-1"
          *ngIf="formLoading"
          [theme]="{ height: '40px' }"
          count="1"
          appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader
          class="geaui-col-desktop-md-4"
          *ngIf="formLoading"
          [theme]="{ height: '40px' }"
          count="1"
          appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader
          class="geaui-col-desktop-md-4"
          *ngIf="formLoading"
          [theme]="{ height: '40px' }"
          count="1"
          appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader
          class="geaui-col-desktop-md-2 geaui-col-desktop-md-start-11"
          *ngIf="formLoading"
          [theme]="{ height: '40px' }"
          count="1"
          appearance="line"></ngx-skeleton-loader>
        <div class="list-row geaui-sub-grid geaui-col-desktop-md-12" *ngFor="let entry of settingsList; index as i">
          <span class="geaui-col-desktop-md-1 geaui-col-desktop-md-start-1">{{ i + 1 }}</span>
          <geaui-autocomplete-select-v2
            class="geaui-col-desktop-md-4"
            [formControlName]="'organization' + entry.controlName"
            [options]="userOrganizations"
            (selectChanged)="onOrgaChanged($event, i)"></geaui-autocomplete-select-v2>
          <geaui-date-picker-v2
            class="geaui-col-desktop-md-4"
            [formName]="'date' + entry.controlName"
            [group]="form"
            (dateChanged)="onDateChanged($event, i)"></geaui-date-picker-v2>
          <span
            class="delete-text geaui-col-desktop-md-2 geaui-col-desktop-md-start-11"
            [ngClass]="{ disabled: sending }"
            (click)="onDelete(i)"
            ><geaui-icon-v2 [color]="sending ? 'grey' : 'cancel-red'" file="16px_remove"></geaui-icon-v2
            >{{ 'X.BUTTON.REMOVE' | translate }}</span
          >
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2 [sending]="sending" [disabled]="isSaveDisabled()" [loading]="formLoading" (click)="save()">
          <span>{{ 'X.BUTTON.SAVE' | translate }}</span>
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
