import { Component, Inject, Input } from '@angular/core';
import { ServiceActivity } from '../../models/service-activity.model';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../../../environments/models/environment.model';

@Component({
  selector: 'advance-related-service-logs',
  templateUrl: './related-service-logs.component.html',
  styleUrl: './related-service-logs.component.scss',
})
export class RelatedServiceLogsComponent {
  @Input() relatedServiceActivities?: ServiceActivity[];
  @Input() loading = false;

  showOverlay = false;

  constructor(@Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration) {}

  toggleOverlay() {
    this.showOverlay = !this.showOverlay;
  }

  handleActivityClick(activity: ServiceActivity) {
    const serviceLogBaseUrl = this.environment.serviceLog.redirectURL;
    const serviceActivityId = activity.serviceActivityId;

    window.open(`${serviceLogBaseUrl}?search=${serviceActivityId}`, '_blank');
    this.toggleOverlay();
  }
}
