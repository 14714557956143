<div class="home-content geaui-grid geaui-sidebar-grid geaui-scrollable-table">
  <div
    class="gea-font-heading-24 geaui-col-desktop-xl-start-7 geaui-col-desktop-md-4 geaui-col-tablet-lg-3 geaui-col-tablet-sm-5 geaui-col-phone-lg-4 geaui-col-phone-sm-2 align-center"
    data-cy="title">
    {{ 'TICKET-LIST.SERVICE-REQUESTS' | translate }}
  </div>
  <geaui-multi-select-v2
    class="multiselect align-center geaui-col-desktop-xl-3 geaui-col-desktop-md-3 geaui-col-tablet-lg-3 geaui-col-tablet-sm-3 geaui-col-phone-lg-4 geaui-col-phone-sm-2"
    [selected]="selectedProducts"
    [options]="products"
    [hideSearchbar]="true"
    [enableTooltipOptionDescription]="true"
    [label]="'TICKET-LIST.EQUIPMENT' | translate"
    (selectChanged)="onProductsChanged($event)"
    (clearButtonClick)="onClearSelections()"
    data-cy="products">
  </geaui-multi-select-v2>
  <geaui-search-bar-v2
    class="searchbar justify-center align-center width-full geaui-col-desktop-xl-3 geaui-col-desktop-md-3 geaui-col-tablet-lg-3 geaui-col-tablet-sm-3 geaui-col-phone-lg-4 geaui-col-phone-sm-2"
    [placeholder]="'TICKET-LIST.TICKET-SEARCH' | translate"
    [hasSuggestions]="false"
    [initialValue]="searchInput"
    (valueChanged)="searchInput = $event"
    (searchButtonClicked)="onSearchTickets()"
    (keydown)="onSearchTicketsByClickEnter($event)"
    (clearEvent)="clearSearch()"
    data-cy="search-bar">
  </geaui-search-bar-v2>
  <div
    class="create-ticket-btn-container align-center geaui-col-desktop-xl-2 geaui-col-desktop-md-2 geaui-col-tablet-lg-3 geaui-col-tablet-sm-3 geaui-col-phone-lg-4 geaui-col-phone-sm-2">
    <geaui-button-v2
      [pTooltip]="'X.INFO.NO_ACCESS.RIGHT_MISSING' | translate"
      [tooltipDisabled]="hasCreatePermission"
      [flex]="true"
      [disabled]="!hasCreatePermission"
      (click)="addTicket()"
      tooltipPosition="bottom"
      tooltipStyleClass="p-tooltip-light-blue"
      preIcon="a-chat"
      data-cy="request-support">
      {{ 'TICKET-LIST.TICKET-CREATE' | translate }}
    </geaui-button-v2>
  </div>
  <geaui-table-v2
    class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
    [tableId]="ticketTableService.TABLE_ID"
    [columnDefinitions]="ticketColumns"
    [data]="ticketData"
    [loading]="loading"
    [defaultSettings]="ticketTableService.defaultTableSettings"
    [noDataText]="!hasReadPermission && ('TICKET-LIST.NO-RESULTS' | translate)"
    [sortable]="true"
    [customNoData]="true"
    [totalRecords]="entryCount"
    [rowsClickable]="true"
    [highlightRow]="highlightRow"
    [uniquePropertyKey]="'id'"
    (rowClicked)="onEdit($event)"
    data-cy="support-table">
    <advance-no-memberships *ngIf="!hasMemberships"></advance-no-memberships>
    <advance-no-entries *ngIf="entryCount === 0 && hasMemberships"></advance-no-entries>
  </geaui-table-v2>
</div>
