<geaui-page-content-layout>
  <ng-container page-header>
    <geaui-page-header
      [withSidebar]="true"
      [pageTitle]="predecessorTicketId ? 'TICKET-LIST.TICKET-CREATE-SUCCESSOR' : 'TICKET-LIST.TICKET-CREATE'"
      (backArrowClicked)="navigateToTableCheck()">
    </geaui-page-header>
  </ng-container>

  <ng-container page-content>
    <div class="geaui-grid geaui-sidebar-grid">
      <div
        class="grid-rows-height geaui-sub-grid geaui-col-desktop-md-4 geaui-col-desktop-lg-4 geaui-col-desktop-xl-4 geaui-col-phone-lg-8 geaui-col-desktop-xl-start-7">
        <span
          class="gea-font-heading-20 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8">
          {{ 'TICKETS.CREATE.BASIC-INFO' | translate }}
        </span>
        <geaui-select-v2
          class="geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8"
          id="organizationId"
          [ngClass]="userOrganizations.length > 1 ? 'organization-dropdown' : 'gea-field-left'"
          [loading]="formLoading"
          [group]="createTicketForm"
          [options]="userOrganizations"
          [label]="'TICKETS.GENERAL.ORGANIZATION.TITLE' | translate"
          [disabled]="isOrganizationDisabled"
          [submitted]="submitted"
          [required]="true"
          (selectChanged)="onOrgaChanged()"
          data-cy="organizations">
        </geaui-select-v2>
        <geaui-select-v2
          class="geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8"
          id="registeredProductId"
          [loading]="formLoading"
          [group]="createTicketForm"
          [options]="registeredProductIdSelectOptions"
          [label]="'TICKETS.CREATE.EQUIPMENT.TITLE' | translate"
          [submitted]="submitted"
          [required]="equipmentRequired"
          [disabled]="equipmentDisabled"
          (selectChanged)="onEquipmentChanged()"
          data-cy="products">
        </geaui-select-v2>
        <geaui-select-v2
          class="geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8"
          id="partnerId"
          *ngIf="showPartner"
          [loading]="formLoading"
          [group]="createTicketForm"
          [options]="dealerSelectOptions"
          [label]="'TICKETS.CREATE.DEALER.TITLE' | translate"
          [submitted]="submitted"
          [required]="true"
          data-cy="dealer-list">
        </geaui-select-v2>
        <geaui-select-v2
          class="geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8"
          id="serviceCategory"
          [loading]="formLoading"
          [group]="createTicketForm"
          [options]="categorySelectOptions"
          [label]="'TICKETS.CREATE.CATEGORY.TITLE' | translate"
          [submitted]="submitted"
          [required]="true"
          [disabled]="serviceCategoryDisabled"
          (selectChanged)="onCategoryChanged()"
          data-cy="service-categories">
        </geaui-select-v2>
        <geaui-input-v2
          class="geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8"
          id="predecessorTicketId"
          *ngIf="predecessorTicketId"
          [label]="'TICKETS.CREATE.TICKET.PREDECESSOR-TICKET-ID' | translate"
          [group]="createTicketForm"
          [loading]="formLoading"
          [disabled]="true"
          [floatLabelWhileDisabled]="true"
          data-cy="predecessorTicketId">
        </geaui-input-v2>
      </div>
      <div class="geaui-col-desktop-md-1 geaui-col-desktop-lg-1 geaui-col-desktop-xl-2"></div>
      <div
        class="line-between-grids geaui-sub-grid geaui-col-desktop-md-6 geaui-col-desktop-lg-6 geaui-col-desktop-xl-12 geaui-col-phone-lg-8"></div>
      <div
        class="grid-rows-height-2 geaui-sub-grid geaui-col-desktop-md-6 geaui-col-desktop-lg-6 geaui-col-desktop-xl-6 geaui-col-phone-lg-8">
        <span
          class="gea-font-heading-20 geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8">
          {{ 'TICKETS.CREATE.REQUEST-MESSAGE' | translate }}
        </span>
        <div
          class="title-toggle-container geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8">
          <div class="input-wrapper">
            <geaui-input-v2
              id="subject"
              [label]="'TICKETS.CREATE.TICKET.TITLE' | translate"
              [group]="createTicketForm"
              [loading]="formLoading"
              [submitted]="submitted"
              [required]="true"
              [disabled]="isSubjectDisabled"
              [floatLabelWhileDisabled]="isSubjectDisabled"
              [placeholder]="'TICKETS.CREATE.TICKET.TITLE' | translate"
              data-cy="subject">
            </geaui-input-v2>
          </div>
          <div class="toggle-wrapper">
            <geaui-toggle-v2
              #toggleComponent
              [label]="'TICKETS.CREATE.AUTO-GENERATE' | translate"
              [loading]="formLoading"
              [disabled]="createButtonLoading"
              [initialValue]="generateSubject"
              [toggleState]="generateSubject"
              (toggled)="toggleSubjectGeneration()"
              data-cy="subject-toggle">
            </geaui-toggle-v2>
          </div>
        </div>
        <div
          class="description-textfield geaui-col-desktop-md-12 geaui-col-desktop-lg-12 geaui-col-desktop-xl-24 geaui-col-phone-lg-8">
          <geaui-textfield-v2
            id="description"
            [loading]="formLoading"
            [label]="'TICKETS.CREATE.DESCRIPTION' | translate"
            [group]="createTicketForm"
            [submitted]="submitted"
            [showRequiredErrorWithoutSubmit]="hasDescriptionValue"
            [required]="true"
            data-cy="description">
          </geaui-textfield-v2>
          <div class="attachments-container">
            <geaui-button-v2
              [loading]="formLoading"
              [disabled]="createButtonLoading"
              [type]="ButtonType.SECONDARY"
              (click)="toggleAttachmentView()"
              preIcon="attach"
              data-cy="open-attachments">
              {{ 'TICKETS.CREATE.ATTACH-FILES' | translate }}
            </geaui-button-v2>
            <div *ngIf="newAttachments.length >= 1">
              <span class="gea-font-heading-16 primary-color padding-x-16">
                {{
                  (newAttachments.length === 1 ? 'TICKETS.CREATE.FILE-ATTACHED' : 'TICKETS.CREATE.FILES-ATTACHED')
                    | translate: { fileNumber: newAttachments.length }
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <geaui-attachments-v2
      [showAttachmentView]="showAttachmentSideModal"
      [removeFilesOnClose]="false"
      [hasPermission]="hasCreatePermission"
      [showSaveButton]="false"
      [maxFileSize]="attachmentsService.maxFileSizeInBytes"
      [creatingUser]="'TICKETS.DETAIL.ATTACHMENT-UPLOADED-CUSTOMER' | translate"
      (filesChanged)="updateAttachments($event)"
      (closeAttachmentView)="toggleAttachmentView()"
      (saveFilesClicked)="uploadFiles($event)"
      data-cy="attachment-side-modal">
    </geaui-attachments-v2>
  </ng-container>

  <ng-container page-footer>
    <geaui-page-footer [withSidebar]="true">
      <ng-container footer-button>
        <geaui-button-v2
          class="create-button"
          [type]="'primary'"
          [postIcon]="true"
          [sending]="createButtonLoading"
          [loading]="formLoading"
          [disabled]="!this.createTicketForm.valid || (equipmentRequired && !this.createTicketForm.get('registeredProductId')?.getRawValue().name)"
          (click)="createTicket()"
          data-cy="send-request">
          <span>{{ 'TICKETS.CREATE.SEND-REQUEST' | translate }}</span>
        </geaui-button-v2>
      </ng-container>
    </geaui-page-footer>
  </ng-container>
</geaui-page-content-layout>
