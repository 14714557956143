import { Router } from '@angular/router';
import { FormatWidth, getLocaleDateFormat, getLocaleTimeFormat } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';

import {
  ApiErrorResponse,
  ColumnDefinition,
  ErrorHandlerV2Service,
  FilterTableSettings,
  SelectOption,
  TableServiceV2,
  UserState,
} from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { catchError, concatMap, filter, of, take, tap } from 'rxjs';
import { TicketsService } from './services/tickets.service';
import { PermissionsService } from './services/permissions.service';
import { ProductSelectionService } from './services/product-selection.service';
import { ProductSelection } from './models/product-selection.model';
import { ContactIdBannerService } from './services/contact-id-banner.service';
import { ProductService } from './services/product.service';
import { SupportAppPermissions, Ticket, TicketList } from '../api/v1';
import { TicketDetails } from './models/ticket-list-entry.model';
import { Store } from '@ngxs/store';
import { TicketTableService } from './services/ticket-table.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'advance-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit {
  hasCreatePermission = false;
  hasMemberships = false;
  hasReadPermission = true;
  ticketColumns: ColumnDefinition[] = [...this.ticketTableService.ticketColumns];
  ticketData: TicketDetails[] = [];
  loading = true;
  entryCount = 0;
  selectedProducts: SelectOption[] = [];
  products: SelectOption[] = [];
  highlightRow = '';
  searchInput = '';
  filterTableSettings: FilterTableSettings | undefined;

  private tableSettingsUpdated = false;

  constructor(
    private tableService: TableServiceV2,
    private translateService: TranslateService,
    private ticketsService: TicketsService,
    private errorHandlerService: ErrorHandlerV2Service,
    private router: Router,
    private permissionsService: PermissionsService,
    private productSelectionService: ProductSelectionService,
    private store: Store,
    private contactIdBannerService: ContactIdBannerService,
    private productService: ProductService,
    private destroyed$: DestroyRef,
    public ticketTableService: TicketTableService
  ) {
    this.highlightRow = this.router.getCurrentNavigation()?.extras?.state?.['highlightRow'] as string;
  }

  ngOnInit(): void {
    this.store
      // This is the official syntax of ngxs
      // eslint-disable-next-line @typescript-eslint/unbound-method
      .select(UserState.user)
      .subscribe((user) => (this.hasMemberships = (user?.memberships && user?.memberships?.length > 0) ?? false));

    this.permissionsService
      .getPermissions()
      .pipe(take(1))
      .subscribe({
        next: (permissions: SupportAppPermissions) => {
          if (!permissions.hasContactId) {
            this.contactIdBannerService.show();
          }
          this.hasCreatePermission = permissions.ticketCreatable;
        },
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT'),
      });

    this.productSelectionService
      .getSelectedProducts()
      .pipe(
        take(1),
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
          return of({ organizations: [] } as ProductSelection);
        }),
        tap((productSelection: ProductSelection) => {
          this.updateProductSelections(productSelection);
          this.updateSelectedProductsFilter(this.selectedProducts);
        }),
        concatMap(() => this.tableService.getFilterTableSettings(this.ticketTableService.TABLE_ID)),
        filter((tableSettings) => JSON.stringify(this.filterTableSettings) !== JSON.stringify(tableSettings)),
        takeUntilDestroyed(this.destroyed$)
      )
      .subscribe({
        next: (tableSettings) => {
          this.filterTableSettings = tableSettings;
          this.searchInput = tableSettings.searchValue ?? '';
          this.tableSettingsUpdated = !!this.searchInput;
          this.reloadTicketData(tableSettings);
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
        },
      });

    this.tableService.actions.pipe(takeUntilDestroyed(this.destroyed$)).subscribe((action) => {
      if (action.action === 'createPredecessor') {
        const rowData = action.rowData as TicketDetails;
        this.addTicket(rowData.id);
      }
    });
  }

  updateProductSelections(productSelection: ProductSelection) {
    const tmpProducts: SelectOption[] = [];
    const tmpSelectedProducts: SelectOption[] = [];

    productSelection.organizations.forEach((organization) => {
      organization.products.forEach((product) => {
        tmpProducts.push({ value: product.id, name: product.displayName });
        if (product.selected) {
          tmpSelectedProducts.push({ value: product.id, name: product.displayName });
        }
      });
    });

    this.products = tmpProducts;
    this.selectedProducts = tmpSelectedProducts;
  }

  applyFilter(tableFilter: FilterTableSettings) {
    this.reloadTicketData(tableFilter);
  }

  reloadTicketData(tableFilter?: FilterTableSettings) {
    this.loading = true;
    this.ticketsService
      .getTickets(tableFilter)
      .pipe(take(1))
      .subscribe({
        next: this.receiveTicketData.bind(this),
        error: this.receiveError.bind(this),
      });
  }

  receiveTicketData(tickets: TicketList) {
    this.ticketData = tickets.pageEntries.map((pageEntry) => {
      const products = pageEntry.products;
      return {
        ...pageEntry,
        mainProductDescription: this.productService.getMainProductDisplayName(products),
        relatedServiceLogs: pageEntry.relatedServiceLogIds?.length,
      } as TicketDetails;
    });
    this.entryCount = tickets.entryCount;
    this.loading = false;
  }

  getDateFormat(formatWidth: FormatWidth): string {
    return `${getLocaleDateFormat(this.translateService.currentLang, formatWidth)} ${getLocaleTimeFormat(
      this.translateService.currentLang,
      formatWidth
    )}`;
  }

  receiveError(e: ApiErrorResponse) {
    if (e.error.errorCode === 'SUPPORT_02_0001') {
      this.hasReadPermission = false;
      this.loading = false;
      return;
    }
    this.loading = false;
    this.tableService.setFilterTableSettings(this.ticketTableService.TABLE_ID, {
      columns: {},
      multiSelectFilter: this.filterTableSettings?.multiSelectFilter,
    });
    this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
  }

  onProductsChanged(selectedOptions: SelectOption[]) {
    this.productSelectionService.updateSelectedProducts(selectedOptions);
    this.updateSelectedProductsFilter(selectedOptions);
  }

  onClearSelections() {
    this.productSelectionService.updateSelectedProducts([]);
    this.tableService.updateFilterTableSettings(this.ticketTableService.TABLE_ID, {
      columns: {},
      multiSelectFilter: [],
    });
  }

  onSearchTickets() {
    if (!this.searchInput) {
      this.clearSearch();
      return;
    }
    this.updateTableSettings();
  }

  onSearchTicketsByClickEnter(event: KeyboardEvent) {
    if (event.code !== 'Enter') return;
    this.onSearchTickets();
  }

  addTicket(predecessorTicketId?: string) {
    void this.router.navigate(['/tickets/create'], {
      queryParams: { predecessorTicketId },
    });
  }

  onEdit(ticketEntry: Ticket) {
    void this.router.navigate([`/tickets/${ticketEntry.id}`]);
  }

  clearSearch() {
    if (this.tableSettingsUpdated) {
      this.searchInput = '';
      this.clearTableSettings();
    }
  }

  updateSelectedProductsFilter(selectedProducts: SelectOption[]) {
    this.tableService.updateFilterTableSettings(this.ticketTableService.TABLE_ID, {
      columns: {},
      multiSelectFilter: selectedProducts,
      page: 0,
    });
  }

  private updateTableSettings() {
    this.tableService.updateFilterTableSettings(this.ticketTableService.TABLE_ID, {
      columns: {},
      search: this.searchInput !== '',
      searchValue: this.searchInput,
      page: 0,
    });
    this.tableSettingsUpdated = true;
  }

  private clearTableSettings() {
    this.tableService.updateFilterTableSettings(this.ticketTableService.TABLE_ID, {
      columns: {},
      search: false,
      searchValue: '',
    });
    this.tableSettingsUpdated = false;
  }
}
