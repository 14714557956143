<div class="comment-container" #scrollContainer data-cy="ticket-interactions-container">
  <div class="comments" *ngIf="!loading && !loadingForm; else commentsLoading">
    <div
      class="comment"
      *ngFor="let comment of _interactions; let isLast = last"
      [ngClass]="comment.initiatorCode === InitiatorCode.INBOUND_TO_GEA ? 'outgoing' : 'incoming'">
      <div class="date-separator gea-font-subline-14" *ngIf="comment.showDateSeparator">
        <span *ngIf="!comment.isToday; else showTodayDivider" data-cy="date-separator">
          {{ comment.creationDateTime | userTimezoneDate: 'shortDate' }}
        </span>
        <ng-template #showTodayDivider>
          {{ 'TICKETS.DETAIL.COMMENT.TODAY-DIVIDER' | translate }}
        </ng-template>
      </div>
      <div class="from-container" *ngIf="comment.showSenderInformation">
        <div class="avatar" data-cy="avatar-container">
          <img
            *ngIf="comment.initiatorCode === InitiatorCode.OUTBOUND_FROM_GEA; else initials"
            src="assets/images/gea-avatar.svg"
            alt="GEA Logo" />
          <ng-template #initials>
            <div class="initials gea-font-heading-14" data-cy="from-party-initials">
              {{ comment.fromPartyInitials }}
            </div>
          </ng-template>
        </div>
        <div class="from gea-font-subline-14" data-cy="from-party-name">
          {{
            comment.fromPartyName ??
              (comment.initiatorCode === InitiatorCode.INBOUND_TO_GEA
                ? ('TICKETS.DETAIL.UPLOADED-FROM-CUSTOMER.FALLBACK' | translate)
                : ('TICKETS.DETAIL.UPLOADED-FROM.FALLBACK' | translate))
          }}
        </div>
        <span class="time gea-font-heading-14" data-cy="message-time">
          {{ comment.creationDateTime | userTimezoneDate: 'shortTime' }}
        </span>
      </div>
      <div
        class="message-container"
        [ngClass]="{ 'reduced-margin': !comment.showSenderInformation, 'last-message': isLast }"
        data-cy="comment-message">
        <div class="text">{{ comment.text }}</div>
      </div>
    </div>
  </div>
</div>

<div
  class="send-comment-container"
  *ngIf="!loading && !loadingForm; else sendCommentLoading"
  [pTooltip]="
    _ticket?.statusCode == StatusCode.COMPLETED
      ? ('TICKET.COMPLETED.EDIT-NOT-POSSIBLE' | translate)
      : ('TICKETS.NO_CREATE_PERMISSION' | translate)
  "
  [tooltipDisabled]="hasEditPermission && _ticket?.statusCode !== StatusCode.COMPLETED"
  data-cy="send-comment-container">
  <geaui-input-v2
    class="comment-input"
    id="sendComment"
    [group]="sendCommentForm"
    [placeholder]="'TICKETS.DETAIL.COMMENT.TEXTFIELD-PLACEHOLDER' | translate"
    [disabled]="!hasEditPermission || _ticket?.statusCode === StatusCode.COMPLETED"
    [suffixIconClickable]="true"
    (suffixIconClicked)="openAttachmentView.emit()"
    (keydown)="onInputEnterClick($event)"
    (valueChanged)="sendCommentValueChanged($event)"
    suffixIcon="attach">
  </geaui-input-v2>
  <geaui-button-v2
    [sending]="isSendingComment"
    [disabled]="!hasEditPermission || loading || sendCommentEmpty"
    (click)="sendComment()"
    preIcon="send">
  </geaui-button-v2>
</div>

<!-- Shadow Loading -->
<ng-template #commentsLoading>
  <div class="loading-comments" *ngIf="loadingForm">
    <div class="inline align-right">
      <ng-template [ngTemplateOutlet]="commentHeaderLoading"></ng-template>
    </div>
    <ngx-skeleton-loader
      [theme]="{ height: '70px', width: '60%', 'margin-bottom': '18px', float: 'right' }"
      count="1"
      appearance="line">
    </ngx-skeleton-loader>

    <div class="inline align-left">
      <ng-template [ngTemplateOutlet]="commentHeaderLoading"></ng-template>
    </div>
    <ngx-skeleton-loader [theme]="{ height: '70px', width: '60%', 'margin-bottom': '18px' }" count="1" appearance="line">
    </ngx-skeleton-loader>

    <div class="inline align-right">
      <ng-template [ngTemplateOutlet]="commentHeaderLoading"></ng-template>
    </div>
    <ngx-skeleton-loader [theme]="{ height: '70px', width: '60%', float: 'right' }" count="1" appearance="line">
    </ngx-skeleton-loader>
  </div>
</ng-template>
<ng-template #commentHeaderLoading>
  <ngx-skeleton-loader
    class="name-loading"
    [theme]="{ height: '20px', width: '100px', 'margin-bottom': '16px', 'margin-right': '8px' }"
    count="1"
    appearance="line">
  </ngx-skeleton-loader>
  <ngx-skeleton-loader
    class="time-loading"
    [theme]="{ height: '20px', width: '30px', 'margin-bottom': '16px', 'margin-right': '16px' }"
    count="1"
    appearance="line">
  </ngx-skeleton-loader>
  <ngx-skeleton-loader
    class="avatar-loading"
    [theme]="{ height: '32px', width: '32px', 'margin-bottom': '16px' }"
    count="1"
    appearance="circle">
  </ngx-skeleton-loader>
</ng-template>
<ng-template #sendCommentLoading>
  <div class="send-comment-container">
    <ngx-skeleton-loader class="comment-input" [theme]="{ height: '48px', width: '100%' }" count="1" appearance="line">
    </ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ height: '40px', width: '72px', 'border-radius': '50px' }" count="1" appearance="line">
    </ngx-skeleton-loader>
  </div>
</ng-template>
