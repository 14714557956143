import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SupportApiService } from '../../api/v1';

@Injectable({
  providedIn: 'root',
})
export class ServiceCategoryService {
  constructor(private api: SupportApiService) {}

  public getServiceCategories(organizationId?: string): Observable<string[]> {
    if (organizationId === undefined) {
      return this.api.getServiceCategories();
    }
    return this.api.getServiceCategoriesForOrganization(organizationId);
  }
}
