import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerV2Service, SnackbarService } from '@gea/digital-ui-lib';

import { Interaction } from '../../models/interaction.model';
import { TicketsService } from '../../services/tickets.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { InitiatorCode, StatusCode } from '../../../api/v1';
import { TicketDetails } from '../../models/ticket-list-entry.model';

@Component({
  selector: 'advance-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnDestroy, AfterViewChecked {
  @Input() set interactions(interactions: Interaction[]) {
    this._interactions = interactions;
    this.latestDateTime = undefined;
    this.initializeInteractions();
    this.initializeDateDividerValues();
    this.shouldScrollToBottom = true;
  }

  @Input() loadingForm = false;

  @Input() set ticket(ticket: TicketDetails) {
    this._ticket = ticket;
    this.ticketId = ticket?.id ?? '';
    this.hasEditPermission = ticket?.editable ?? false;
  }

  @Output() openAttachmentView = new EventEmitter<void>();
  @ViewChild('scrollContainer') scrollContainer: ElementRef<HTMLDivElement> | undefined;

  readonly StatusCode = StatusCode;

  isSendingComment = false;
  hasEditPermission = false;
  ticketId = '';
  latestDateTime: Date | undefined;
  InitiatorCode = InitiatorCode;
  sendCommentForm!: FormGroup;
  sendCommentEmpty = true;
  loading = false;
  outgoingInteractions: Interaction[] = [];
  shouldScrollToBottom = true;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  _interactions: Interaction[] = [];
  _ticket: TicketDetails | undefined;

  constructor(
    public translateService: TranslateService,
    private ticketService: TicketsService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private errorHandlerService: ErrorHandlerV2Service
  ) {
    this.initializeSendCommentForm();
  }

  ngAfterViewChecked() {
    if (this.scrollContainer && this.shouldScrollToBottom && !this.loadingForm) {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
      this.shouldScrollToBottom = false;
    }
  }

  initializeSendCommentForm() {
    // that is just how angular forms syntax works
    /* eslint-disable @typescript-eslint/unbound-method */
    this.sendCommentForm = this.formBuilder.group({
      sendComment: new FormControl<string>('', []),
    });
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  initializeInteractions() {
    this._interactions.sort((date1, date2) => {
      return new Date(date1.creationDateTime).getTime() - new Date(date2.creationDateTime).getTime();
    });

    this._interactions.forEach((currentComment, index, array) => {
      currentComment.fromPartyInitials = this.getInitialsFromPartyName(currentComment.fromPartyName);

      if (currentComment.initiatorCode === InitiatorCode.INBOUND_TO_GEA) {
        this.outgoingInteractions.push(currentComment);
      }

      const nextComment = array[index + 1];
      this.checkIfCanCombineMessages(currentComment, nextComment);

      if (currentComment.showSenderInformation === undefined) {
        currentComment.showSenderInformation = true;
      }
    });
  }

  checkIfCanCombineMessages(currentComment: Interaction, nextComment: Interaction) {
    if (!nextComment) return;

    if (
      currentComment.initiatorCode === nextComment?.initiatorCode &&
      currentComment?.fromPartyName === nextComment?.fromPartyName
    ) {
      if (!nextComment?.creationDateTime) return;

      nextComment.showSenderInformation = this.isTimeDifferenceOutOfLimit(
        nextComment?.creationDateTime,
        currentComment?.creationDateTime
      );
    }
  }

  getInitialsFromPartyName(name?: string): string {
    if (!name) return '-';

    const nameParts = name.split(/[ ,]+/);
    if (nameParts.length === 2) {
      return nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase();
    }

    if (nameParts.length >= 1) {
      return nameParts[0].charAt(0).toUpperCase();
    }
    return '-';
  }

  initializeDateDividerValues() {
    this._interactions.forEach((comment) => {
      if (comment.creationDateTime) {
        comment.showDateSeparator = this.checkIfDateIsAfter(comment.creationDateTime);
        comment.isToday = this.checkIfDateIsToday(comment.creationDateTime);
      }
    });
  }

  checkIfDateIsAfter(creationDate: string): boolean {
    const currentDateTime = new Date(creationDate);
    currentDateTime.setHours(0, 0, 0, 0);

    if (!this.latestDateTime) {
      this.latestDateTime = new Date(currentDateTime);
      return true;
    }

    if (this.latestDateTime.getTime() < currentDateTime.getTime()) {
      this.latestDateTime = new Date(currentDateTime);
      return true;
    }

    return false;
  }

  checkIfDateIsToday(creationDate: string): boolean {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const inputDate = new Date(creationDate);
    inputDate.setHours(0, 0, 0, 0);

    return currentDate.getTime() === inputDate.getTime();
  }

  sendCommentValueChanged(value: string) {
    this.sendCommentEmpty = !value;
  }

  isTimeDifferenceOutOfLimit(currentMessageDateString: string, prevMessageDateString?: string): boolean {
    if (!prevMessageDateString) return true;

    const oneMinutesInMillis = 60 * 1000;
    const prevDate = new Date(prevMessageDateString);
    const currentDate = new Date(currentMessageDateString);

    return Math.abs(currentDate.getTime() - prevDate.getTime()) >= oneMinutesInMillis;
  }

  sendComment() {
    const message = this.sendCommentForm.get('sendComment')?.value as string;
    const fromPartyName = this.translateService.instant('TICKETS.DETAIL.UPLOADED-FROM-CUSTOMER.FALLBACK') as string;
    if (message?.trim()) {
      this.isSendingComment = true;
      this.sendCommentForm.get('sendComment')?.disable();

      this.ticketService
        .createConversationMessage(this.ticketId, message)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.snackbarService.add({
              summary: 'SUCCESS-SNACKBAR.SUCCESS',
              detail: 'TICKETS.CREATE.CONVERSATION-SUCCESS',
              severity: 'success',
            });

            const currentDate = new Date().toISOString();
            const newInteraction: Interaction = {
              showDateSeparator: this.checkIfDateIsAfter(currentDate),
              isToday: true,
              creationDateTime: currentDate,
              text: message,
              initiatorCode: InitiatorCode.INBOUND_TO_GEA,
              fromPartyName: fromPartyName,
              fromPartyInitials: this.getInitialsFromPartyName(fromPartyName),
              showSenderInformation: this.isTimeDifferenceOutOfLimit(
                currentDate,
                this.getLatestOutgoingMessage()?.creationDateTime
              ),
            };
            this._interactions.push(newInteraction);
            this.outgoingInteractions.push(newInteraction);
            this.shouldScrollToBottom = true;
            this.sendCommentForm.get('sendComment')?.reset();
            this.sendCommentForm.get('sendComment')?.enable();
            this.isSendingComment = false;
          },
          error: (error: HttpErrorResponse) => {
            this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
            this.isSendingComment = false;
            this.sendCommentForm.get('sendComment')?.enable();
          },
        });
    }
  }

  getLatestOutgoingMessage(): Interaction | undefined {
    return this.outgoingInteractions[this.outgoingInteractions?.length - 1];
  }

  onInputEnterClick(event: KeyboardEvent) {
    if (event.code !== 'Enter') return;
    const message = this.sendCommentForm.get('sendComment')?.value as string;
    if (message && message.length > 0) this.sendComment();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
