import { Injectable } from '@angular/core';
import {
  ColumnDefinition,
  ColumnRendererComponent,
  ContextMenuComponent,
  DateRendererComponent,
  HeaderRendererComponent,
  RendererUtilService,
  SelectFilterComponent,
  SelectFilterOption,
  SelectFilterRenderer,
  SortDirection,
  TranslationRendererComponent,
  VisibilitySettingsRendererComponent,
} from '@gea/digital-ui-lib';
import { StatusRendererComponent } from '../ticket-status/status-renderer/status-renderer.component';
import { StatusCode, Ticket } from '../../api/v1';
import { ServiceCategoryService } from './service-category.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TicketTableService {
  readonly TABLE_ID = 'support-app-ticket-list';
  readonly SortDirection = SortDirection;
  readonly StatusCode = StatusCode;

  serviceCategories: SelectFilterOption[] = [];

  ticketColumns: ColumnDefinition[] = [
    {
      key: 'lastChangeDateTime',
      displayName: 'TICKET-LIST.LAST-MODIFIED',
      renderer: {
        component: DateRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          format: 'short',
        },
      },
      width: 170,
    },
    {
      key: 'id',
      displayName: 'TICKET-LIST.TICKET-NR',
      width: 140,
    },
    {
      key: 'subject',
      displayName: 'TICKET-LIST.TITLE',
      width: 500,
    },
    {
      key: 'organization',
      displayName: 'TICKET-LIST.ORGANIZATION',
      width: 300,
    },
    {
      key: 'mainProductDescription',
      displayName: 'TICKET-LIST.PRODUCT',
      width: 300,
    },
    {
      displayName: 'TICKET-LIST.CATEGORY',
      key: 'serviceCategory',
      width: 250,
      sortable: true,
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          prefix: 'TICKETS.SERVICE-CATEGORY.',
        },
      },
      filter: {
        component: SelectFilterComponent,
        config: {
          options: this.serviceCategories,
        },
      },
    },
    this.rendererUtilService.createEnumColumnExcludeValues(
      'TICKET-LIST.STATUS',
      'statusCode',
      StatusCode,
      'TICKETS.STATUS.',
      260,
      {
        component: StatusRendererComponent as ColumnRendererComponent<unknown>,
      },
      [StatusCode.NOT_FOUND],
      true
    ),
    {
      key: 'relatedServiceLogs',
      displayName: 'TICKET-LIST.SERVICE-LOGS',
      width: 300,
      visible: false,
      sortable: false,
    },
    {
      displayName: '',
      key: 'settings',
      frozen: 'right',
      sortable: false,
      width: 50,
      headerRenderer: {
        component: VisibilitySettingsRendererComponent as HeaderRendererComponent<unknown>,
        config: {
          columns: [
            {
              key: 'lastChangeDateTime',
              displayName: 'TICKET-LIST.LAST-MODIFIED',
            },
            {
              key: 'id',
              displayName: 'TICKET-LIST.TICKET-NR',
            },
            {
              key: 'subject',
              displayName: 'TICKET-LIST.TITLE',
            },
            {
              key: 'organization',
              displayName: 'TICKET-LIST.ORGANIZATION',
            },
            {
              key: 'mainProductDescription',
              displayName: 'TICKET-LIST.PRODUCT',
            },
            {
              key: 'serviceCategory',
              displayName: 'TICKET-LIST.CATEGORY',
            },
            {
              key: 'statusCode',
              displayName: 'TICKET-LIST.STATUS',
            },
            {
              key: 'relatedServiceLogs',
              displayName: 'TICKET-LIST.SERVICE-LOGS',
            },
          ],
        },
      },
      renderer: {
        component: ContextMenuComponent as ColumnRendererComponent<unknown>,
        config: {
          items: [
            {
              key: 'createPredecessor',
              text: 'TICKET-LIST.ACTION.CREATE-SUCCESSOR-TICKET',
              icon: 'copy-2',
              action: 'createPredecessor',
              disabledForFunction: (rowData: Ticket) => rowData.statusCode !== 'Completed_5',
            },
          ],
        },
      },
    },
  ];

  defaultTableSettings = {
    columns: {
      lastChangeDateTime: {
        sort: SortDirection.DESCENDING,
      },
    },
  };

  constructor(
    private rendererUtilService: RendererUtilService,
    private serviceCategoryService: ServiceCategoryService
  ) {
    this.updateServiceCategoryOptions();
  }

  updateServiceCategoryOptions() {
    this.serviceCategoryService
      .getServiceCategories()
      .pipe(takeUntilDestroyed(), take(1))
      .subscribe((categories) => {
        this.serviceCategories = categories.map((item) => ({
          name: 'TICKETS.SERVICE-CATEGORY.' + item,
          value: item,
        }));
        const serviceCategoryColumn = this.ticketColumns.find((value) => value.key == 'serviceCategory');
        if (serviceCategoryColumn) {
          (serviceCategoryColumn.filter as SelectFilterRenderer).config.options = this.serviceCategories;
        }
      });
  }
}
