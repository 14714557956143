import { Injectable } from '@angular/core';

import { DialogV2Service } from '@gea/digital-ui-lib';
import { Observable, first, Subject } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class DiscardChangesGuard {
  constructor(private dialogV2Service: DialogV2Service) {}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return !component.canDeactivate() ? this.buildDiscardChangesDialog() : true;
  }

  private buildDiscardChangesDialog(): Observable<boolean> {
    const result$ = new Subject<boolean>();
    this.dialogV2Service.open({
      title: 'X.PROMPT.LEAVE_NO_SAVE.TITLE',
      message: 'X.PROMPT.LEAVE_NO_SAVE.DETAIL',
      yes: 'X.BUTTON.LEAVE',
      no: 'X.BUTTON.CANCEL',
      hideButtons: false,
      confirmCallback: () => {
        result$.next(true);
        result$.complete();
      },
      rejectCallback: () => {
        result$.next(false);
        result$.complete();
      },
    });
    return result$.asObservable().pipe(first());
  }
}
