export * from './apiError';
export * from './attachment';
export * from './attachmentBinary';
export * from './attachmentsIdentifier';
export * from './conversationHistory';
export * from './conversationInteraction';
export * from './createConversationMessageRequest';
export * from './createConversationMessageResponse';
export * from './createTicketEventResponse';
export * from './createTicketEventResponseAttachmentChangesInner';
export * from './createTicketEventResponseConversationChangesInner';
export * from './createTicketEventResponseFieldChangesInner';
export * from './createTicketEventResponseProductIdInner';
export * from './createTicketRequest';
export * from './createTicketResponse';
export * from './dealerInner';
export * from './infoResponse';
export * from './infoResponseBuild';
export * from './infoResponseDatabaseSchema';
export * from './infoResponseGit';
export * from './initiatorCode';
export * from './product';
export * from './relatedTicket';
export * from './serviceCategory';
export * from './servicePriority';
export * from './statusCode';
export * from './supportAppPermissions';
export * from './ticket';
export * from './ticketDateFilter';
export * from './ticketList';
export * from './ticketProduct';
export * from './updateTicketDateFilter';
