/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceCategory } from './serviceCategory';
import { RelatedTicket } from './relatedTicket';
import { Attachment } from './attachment';
import { ServicePriority } from './servicePriority';
import { StatusCode } from './statusCode';
import { TicketProduct } from './ticketProduct';


export interface Ticket { 
    objectId: string;
    id: string;
    subject: string;
    description: string;
    formattedDescription: string;
    organization?: string;
    organizationId: string;
    organizationName: string;
    mdgCustomerNumber?: string;
    createdByName: string;
    statusCode: StatusCode;
    statusCodeName?: string;
    servicePriority: ServicePriority;
    servicePriorityName?: string;
    dataOrigin: string;
    dataOriginName?: string;
    serviceCategory: ServiceCategory;
    serviceCategoryName?: string;
    completionDate: string;
    creationDate: string;
    lastChangeDateTime: string;
    requestedStartDate: string;
    mainProductDescription: string;
    products: Array<TicketProduct>;
    predecessorTicket?: RelatedTicket;
    successorTickets?: Array<RelatedTicket>;
    editable: boolean;
    cloneable?: boolean;
    attachments?: Array<Attachment>;
    relatedServiceLogIds?: Array<string>;
}
export namespace Ticket {
}


