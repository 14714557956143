import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiErrorResponse,
  ButtonTypeEnum,
  DialogV2Service,
  ErrorHandlerV2Service,
  FeatureFlagConfigurationState,
  SelectOption,
  SnackbarService,
  ToggleV2Component,
  UserFileV2,
} from '@gea/digital-ui-lib';
import { catchError, concatMap, EMPTY, filter, forkJoin, map, of, take } from 'rxjs';

import { TicketsService } from '../services/tickets.service';
import { ServiceCategoryService } from '../services/service-category.service';
import { AttachmentsService } from '../services/attachments.service';
import { PermissionsService } from '../services/permissions.service';
import { SupportGoogleAnalyticsService } from '../services/support-google-analytics.service';
import { ProductSelectionService } from '../services/product-selection.service';
import { Product, ProductSelection, ProductSelectionOrganization } from '../models/product-selection.model';
import { AttachmentBinary, CreateTicketRequest, ServicePriority, SupportAppPermissions, Ticket } from '../../api/v1';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../../environments/models/environment.model';
import { DealerService } from '../services/dealer.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';

@Component({
  selector: 'advance-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
})
export class CreateTicketComponent implements OnInit {
  @ViewChild('toggleComponent', { static: false }) toggleComponent: ToggleV2Component | undefined;

  formLoading = false;
  createButtonLoading = false;
  createTicketForm!: FormGroup;
  ButtonType = ButtonTypeEnum;

  predecessorTicketId?: string;
  initialSelectedCategory: string | undefined;

  categorySelectOptions!: SelectOption[];
  showPartner = false;
  dealerSelectOptions!: SelectOption[];
  registeredProductIdSelectOptions!: SelectOption[];
  userOrganizations: SelectOption[] = [];
  userOrganizationsAndProducts: ProductSelectionOrganization[] = [];
  newAttachments: AttachmentBinary[] = [];
  newTicket!: CreateTicketRequest;
  attachments: UserFileV2[] = [];

  ticketData: Ticket | undefined;
  ticketId: string | undefined;
  submitted = false;
  generateSubject = true;
  equipmentSubject = '';
  categorySubject = '';
  autoGeneratedSubject = '';
  equipmentDisabled = true;
  isSubjectDisabled = true;
  isOrganizationDisabled = false;
  hasDescriptionValue = false;
  showAttachmentSideModal = false;
  hasCreatePermission = false;
  hasEquipmentSelectionOptionalFeature = false;
  loading = false;
  equipmentRequired = false;
  serviceCategoryDisabled = true;
  selectedOrganization?: ProductSelectionOrganization;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ticketsService: TicketsService,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackbarService: SnackbarService,
    public translateService: TranslateService,
    private serviceCategoryService: ServiceCategoryService,
    public attachmentsService: AttachmentsService,
    public googleAnalyticsService: SupportGoogleAnalyticsService,
    private permissionsService: PermissionsService,
    private dealerService: DealerService,
    private dialogService: DialogV2Service,
    private changeDetection: ChangeDetectorRef,
    private productSelectionService: ProductSelectionService,
    private store: Store,
    private destroyed$: DestroyRef,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration
  ) {
    this.initializeCreateTicketForm();
    this.registeredProductIdSelectOptions = [];
  }

  ngOnInit(): void {
    this.formLoading = true;

    this.route.queryParamMap
      .pipe(
        take(1),
        map((params: ParamMap) => params.get('predecessorTicketId'))
      )
      .subscribe({
        next: (predecessorTicketId) => {
          if (predecessorTicketId) {
            this.predecessorTicketId = predecessorTicketId;
          }
        },
      });

    this.permissionsService
      .getPermissions()
      .pipe(take(1))
      .subscribe({
        next: (permissions: SupportAppPermissions) => {
          this.hasCreatePermission = permissions.ticketCreatable;
          if (this.hasCreatePermission) {
            this.loadFormData();

            if (this.googleAnalyticsService.loggedInUser) {
              this.googleAnalyticsService.metaPush('Create-Ticket');
            } else {
              // eslint-disable-next-line no-console
              console.warn('was not able to send ga event');
            }
          } else {
            this.dialogService.open({
              title: 'UI-LIB.ROUTE-GUARD.TITLE',
              message: this.translateService.instant('UI-LIB.ROUTE-GUARD.CONTENT') as string,
              yes: 'UI-LIB.ROUTE-GUARD.BACK-TO-DASHBOARD',
              no: '',
              closable: true,
              hideButtons: false,
              showRejectButton: false,
              confirmCallback: this.confirmNavigateToPortal.bind(this),
            });
          }
        },
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT'),
      });

    this.getFeatureFlags();
  }

  initializeCreateTicketForm() {
    // that is just how angular forms syntax works
    /* eslint-disable @typescript-eslint/unbound-method */
    this.createTicketForm = this.formBuilder.group({
      subject: new FormControl<string>('', [Validators.required]),
      serviceCategory: new FormControl<string>('', [Validators.required]),
      predecessorTicketId: new FormControl<string>({ value: '', disabled: true }),
      partnerId: new FormControl<string>(''),
      organizationId: new FormControl<string>('', [Validators.required]),
      registeredProductId: new FormControl<string>('', [Validators.required]),
      description: new FormControl<string>('', [Validators.required, Validators.minLength(20), Validators.maxLength(1000)]),
    });
    this.createTicketForm.get('registeredProductId')?.disable();
    this.createTicketForm.get('subject')?.disable();
    const descriptionControl = this.createTicketForm.get('description');
    if (descriptionControl) {
      descriptionControl.valueChanges.pipe(takeUntilDestroyed(this.destroyed$)).subscribe(() => {
        this.hasDescriptionValue = true;
      });
    }
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  getFeatureFlags() {
    this.store
      .select(FeatureFlagConfigurationState.features)
      .pipe(
        takeUntilDestroyed(this.destroyed$),
        filter((features) => !!features)
      )
      .subscribe((features) => {
        this.hasEquipmentSelectionOptionalFeature = 'core_support.equiselectionoptional' in features;
      });
  }

  confirmNavigateToPortal() {
    void this.router.navigate([this.environment.portal.redirectURL]);
  }

  loadFormData() {
    const userOrganizationsAndProducts$ = this.productSelectionService.getSelectedProducts().pipe(
      take(1),
      takeUntilDestroyed(this.destroyed$),
      catchError((e: HttpErrorResponse) => {
        this.formLoading = false;
        this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
        return of(undefined);
      })
    );

    // Is only needed for reopening a ticket
    const predecessorTicket$ = this.predecessorTicketId
      ? this.ticketsService.getTicket(this.predecessorTicketId).pipe(
          take(1),
          takeUntilDestroyed(this.destroyed$),
          catchError((e: HttpErrorResponse) => {
            this.formLoading = false;
            this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
            return of(undefined);
          })
        )
      : of(undefined);

    forkJoin([userOrganizationsAndProducts$, predecessorTicket$]).subscribe({
      next: ([userOrganizationsAndProducts, ticket]) => {
        if (userOrganizationsAndProducts) {
          this.initializeUserOrganizationsAndProducts(userOrganizationsAndProducts);
        }

        if (ticket) {
          this.ticketData = ticket;

          if (this.ticketData?.organizationId) {
            this.createTicketForm.get('organizationId')?.disable();
            this.createTicketForm
              .get('organizationId')
              ?.setValue(this.userOrganizations.find((organization) => organization.value === this.ticketData?.organizationId));
            this.onOrgaChanged();
            const mainProduct = this.ticketData?.products?.find((product) => product.main);
            if (mainProduct) {
              const registeredProductIdSelectOption = this.registeredProductIdSelectOptions.find(
                (registeredProduct) => registeredProduct.value === mainProduct?.registeredProductId
              );
              if (registeredProductIdSelectOption) {
                this.createTicketForm.get('registeredProductId')?.setValue(registeredProductIdSelectOption);
              }
            }
          }
          this.createTicketForm.get('predecessorTicketId')?.setValue(this.ticketData?.id);
          this.createTicketForm
            .get('serviceCategory')
            ?.setValue(this.categorySelectOptions.find((category) => category.value === this.ticketData?.serviceCategory));
          this.createTicketForm.get('description')?.setValue(this.ticketData?.description);
          this.initialSelectedCategory = 'general';
        }
        this.formLoading = false;
        this.changeDetection.detectChanges();
        this.onEquipmentChanged();
        this.onCategoryChanged();
      },
    });
  }

  initializeUserOrganizationsAndProducts(userOrganizationsAndProducts: ProductSelection) {
    this.userOrganizationsAndProducts = userOrganizationsAndProducts.organizations;
    this.userOrganizations = this.userOrganizationsAndProducts?.map((organization) => ({
      value: organization.organizationId,
      name: organization.organizationName,
    }));

    if (this.userOrganizations.length === 1) {
      this.createTicketForm.get('organizationId')?.setValue(this.userOrganizations[0]);
      this.isOrganizationDisabled = true;
      this.onOrgaChanged();
    }
  }

  onOrgaChanged() {
    const orgaSelect = this.createTicketForm.get('organizationId')?.getRawValue() as SelectOption;
    const organizationId = orgaSelect?.value as string;
    this.selectedOrganization = this.userOrganizationsAndProducts.find((orga) => organizationId === orga.organizationId);

    this.equipmentRequired = !(
      ['FT_DEALER', 'FT_FARMER'].includes(this.selectedOrganization?.organizationType ?? '') &&
      this.hasEquipmentSelectionOptionalFeature
    );

    const orgaHasProducts = this.selectedOrganization?.products.length != 0;
    if (this.selectedOrganization && orgaHasProducts) {
      this.registeredProductIdSelectOptions = this.selectedOrganization.products
        .map((product: Product) => ({ value: product.id, name: product.displayName }))
        .sort((a, b) => ((a.name || '') > (b.name || '') ? 1 : -1));
      this.equipmentDisabled = false;
    } else {
      this.equipmentDisabled = true;
    }

    this.equipmentSubject = '';
    this.resetFormControl('registeredProductId', true);
    this.resetFormControl('serviceCategory');

    this.togglePartnerDropdown();
    this.fillServiceCategoryDropdown();
  }

  onCategoryChanged() {
    const subjectSelect = this.createTicketForm.get('serviceCategory')?.getRawValue() as SelectOption;
    this.categorySubject = subjectSelect?.name ?? '';
    this.createSubject();
  }

  onEquipmentChanged() {
    const registeredProductSelect = this.createTicketForm.get('registeredProductId')?.getRawValue() as SelectOption;
    const registeredProductId = registeredProductSelect?.name as string;
    this.equipmentSubject = registeredProductId ? registeredProductId + ' / ' : '';
    this.createSubject();
  }

  togglePartnerDropdown() {
    this.resetFormControl('partnerId', true);
    this.showPartner = this.selectedOrganization?.organizationType === 'FT_FARMER';
    if (this.showPartner) {
      this.updateDealerSelectOptions();
    }
  }

  resetFormControl(formControlName: string, resetValue = false) {
    const formControl = this.createTicketForm.get(formControlName);
    if (resetValue) formControl?.setValue('');
    formControl?.markAsPristine();
    formControl?.updateValueAndValidity();
  }

  fillServiceCategoryDropdown() {
    this.serviceCategoryService
      .getServiceCategories(this.selectedOrganization?.organizationId)
      .pipe(
        take(1),
        takeUntilDestroyed(this.destroyed$),
        catchError((e: HttpErrorResponse) => {
          this.formLoading = false;
          this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
          return of();
        })
      )
      .subscribe((serviceCategoryNames) => {
        const serviceCategoryControl = this.createTicketForm.get('serviceCategory');
        const subjectControl = this.createTicketForm.get('subject');

        this.categorySelectOptions =
          serviceCategoryNames?.map((categoryName) => ({
            value: categoryName,
            nameKey: 'TICKETS.SERVICE-CATEGORY.' + categoryName,
          })) ?? [];

        const currentCategory = this.createTicketForm.get('serviceCategory')?.getRawValue() as SelectOption;
        const categoryExists = this.categorySelectOptions.some((category) => category.value === currentCategory.value);

        serviceCategoryControl?.setValue(categoryExists ? currentCategory : '');
        this.categorySubject = categoryExists ? this.categorySubject : '';
        subjectControl?.setValue(this.categorySubject);

        this.serviceCategoryDisabled = false;
      });
  }

  updateDealerSelectOptions() {
    const partnerIdControl = this.createTicketForm.get('partnerId');
    partnerIdControl?.setValue(undefined);
    partnerIdControl?.disable();

    this.dealerService
      .getDealer(this.selectedOrganization?.organizationId as string)
      .pipe(takeUntilDestroyed(this.destroyed$))
      .subscribe((dealers) => {
        partnerIdControl?.enable();
        this.dealerSelectOptions = dealers.map((dealer) => ({
          value: dealer.organizationId,
          name: dealer.organizationName,
        }));

        if (this.dealerSelectOptions.length === 1) {
          partnerIdControl?.setValue(this.dealerSelectOptions[0]);
          partnerIdControl?.disable();
        }
      });
  }

  createSubject() {
    if (this.generateSubject) {
      this.isSubjectDisabled = true;
      this.autoGeneratedSubject = this.equipmentSubject + this.categorySubject;
      this.createTicketForm.get('subject')?.setValue(this.autoGeneratedSubject);
    }
  }

  toggleSubjectGeneration() {
    const currentSubject = this.createTicketForm.get('subject')?.getRawValue() as string;

    if (!this.generateSubject && (!currentSubject || currentSubject === this.autoGeneratedSubject)) {
      this.generateSubjectConfirmation();
    } else if (!this.generateSubject) {
      this.dialogService.open({
        title: 'TICKETS.CREATE.DIALOG.DISCARD-TITLE-CHANGES.TITLE',
        message: this.generateMessageForToggleDialog(),
        yes: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.ACCEPT',
        no: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.REJECT',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: this.generateSubjectConfirmation.bind(this),
        rejectCallback: this.generateSubjectRejection.bind(this),
      });
    } else {
      this.generateSubject = !this.generateSubject;
      this.isSubjectDisabled = false;
    }
  }

  generateMessageForToggleDialog(): string {
    return this.isDataFoundForSubject()
      ? (this.translateService.instant('TICKETS.CREATE.GENERATE-TITLE.DIALOG.MESSAGE', {
          subject: `'${this.equipmentSubject}${this.categorySubject}'`,
        }) as string)
      : (this.translateService.instant('TICKETS.CREATE.GENERATE-TITLE-DIALOG.DEFAULT.MESSAGE') as string);
  }

  isDataFoundForSubject(): boolean {
    const formElements = ['serviceCategory', 'registeredProductId'];
    for (const element of formElements) {
      if (this.createTicketForm.get(element)?.dirty) {
        return true;
      }
    }
    return false;
  }

  generateSubjectConfirmation() {
    this.generateSubject = true;
    this.createSubject();
  }

  generateSubjectRejection() {
    this.generateSubject = false;
    this.isSubjectDisabled = false;
    this.setToggleState(this.generateSubject);
  }

  setToggleState(value: boolean): void {
    const control = this.toggleComponent?.control;
    if (control) {
      control.setValue(value);
    }
  }

  toggleAttachmentView() {
    this.showAttachmentSideModal = !this.showAttachmentSideModal;
  }

  async updateAttachments(attachments: UserFileV2[]) {
    this.attachments = attachments;
    this.newAttachments = await this.attachmentsService.convertToAttachmentBinary(attachments);
  }

  createTicket() {
    this.submitted = true;
    if (this.validateTicketForm()) {
      // that is just how angular forms syntax works
      /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
      const selectedProduct = this.registeredProductIdSelectOptions.find(
        (product) => product?.value === this.createTicketForm?.value?.registeredProductId?.value
      );
      const categoryTranslation = this.translateService.instant(
        `TICKETS.SERVICE-CATEGORY.${this.createTicketForm.value.serviceCategory.value as string}`
      ) as string;
      this.newTicket = {
        serviceCategory: this.createTicketForm.value.serviceCategory.value,
        servicePriority: ServicePriority.URGENT,
        requestedStartDate: new Date().toISOString(),
        description: this.createTicketForm.value.description,
        organizationId: this.createTicketForm.getRawValue().organizationId?.value,
        registeredProductId: this.createTicketForm.getRawValue().registeredProductId?.value,
        subject: this.isSubjectDisabled
          ? this.createSubjectWithTranslation(categoryTranslation, selectedProduct)
          : this.createTicketForm.value.subject,
        partnerId: this.createTicketForm.getRawValue().partnerId?.value,
        /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
        predecessorTicketId: this.predecessorTicketId,
      };

      this.createButtonLoading = true;
      this.createTicketForm.controls['serviceCategory'].disable();
      this.createTicketForm.controls['organizationId'].disable();
      this.createTicketForm.controls['registeredProductId'].disable();
      this.createTicketForm.controls['partnerId'].disable();
      this.createTicketForm.controls['description'].disable();

      const createTicket$ = this.ticketsService.createTicket(this.newTicket).pipe(
        takeUntilDestroyed(this.destroyed$),
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
          this.createButtonLoading = false;
          this.createTicketForm.controls['serviceCategory'].enable();
          this.createTicketForm.controls['organizationId'].enable();
          this.createTicketForm.controls['registeredProductId'].enable();
          this.createTicketForm.controls['description'].enable();
          return EMPTY;
        }),
        concatMap((ticket) => {
          this.ticketId = ticket.id;
          this.googleAnalyticsService.createTicketPush(this.newTicket.serviceCategory, ServicePriority.URGENT);
          if (this.newAttachments.length > 0) {
            return this.attachmentsService.addAttachment(ticket.id, this.newAttachments);
          }
          return of(ticket);
        })
      );

      createTicket$.subscribe({
        next: () => {
          if (this.newAttachments.length > 0) {
            this.googleAnalyticsService.createTicketAttachmentPush(
              this.newTicket.serviceCategory,
              this.newAttachments.map((attachment) => attachment.name)
            );
          }
          this.snackbarService.add({
            summary: 'SUCCESS-SNACKBAR.SUCCESS',
            detail: this.translateService.instant('TICKETS.CREATE.SUCCESS') as string,
            severity: 'success',
          });
          void this.router.navigate(['/tickets'], { state: { highlightRow: this.ticketId } });
        },
        error: (error: ApiErrorResponse) => {
          void this.router.navigate([`/tickets/${this.ticketId || ''}`], { state: { attachments: this.attachments } });
          this.errorHandlerService.handleWithCustomError(
            this.translateService.instant('API.ERROR.CREATE-TICKET.ADD-ATTACHMENT') as string,
            error
          );
        },
      });
    }
  }

  navigateToTable() {
    void this.router.navigate(['/tickets']);
  }

  navigateToTableCheck() {
    if (this.createTicketForm.dirty) {
      this.dialogService.open({
        title: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.TITLE',
        message: this.translateService.instant('TICKETS.CREATE.DIALOG.DISCARD-CHANGES.MESSAGE') as string,
        yes: 'X.BUTTON.LEAVE',
        no: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.REFUSE',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: this.navigateToTable.bind(this),
      });
    } else {
      this.navigateToTable();
    }
  }

  validateTicketForm(): boolean {
    Object.keys(this.createTicketForm.controls).forEach((key) => {
      this.createTicketForm.controls[key].markAsDirty();
    });
    this.createTicketForm.markAllAsTouched();
    this.createTicketForm.updateValueAndValidity();
    return this.createTicketForm.valid;
  }

  createSubjectWithTranslation(category: string, product?: SelectOption): string {
    const productName = product?.name ? `${product.name} / ` : '';
    return `${productName}${category}`;
  }

  uploadFiles(attachments: UserFileV2[]) {
    this.loading = true;
    if (this.ticketId) {
      const ticketId = this.ticketId;
      this.attachmentsService
        .convertToAttachmentBinary(attachments)
        .then((attachments) => {
          this.attachmentsService
            .addAttachment(ticketId, attachments)
            .pipe(concatMap(() => this.attachmentsService.getAttachments(ticketId)))
            .subscribe({
              next: () => {
                this.loading = false;
              },
              error: (err: ApiErrorResponse) => {
                this.loading = true;
                this.handleError(err);
              },
            });
        })
        .catch((error: ApiErrorResponse) => this.handleError(error));
    }
  }

  private handleError(error: ApiErrorResponse) {
    this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
    this.loading = false;
  }
}
