import { Injectable } from '@angular/core';
import { ServiceLogApiService } from './service-log-api.service';
import { ServiceActivityList } from '../models/service-activity.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiServiceInterface } from '@gea/digital-ui-lib';

@Injectable({
  providedIn: 'root',
})
export class ServiceLogService implements ApiServiceInterface {
  apiVersion = '1';
  url = '/activities';

  constructor(private api: ServiceLogApiService) {}

  public getServiceLogActivities(serviceLogIds: string[]): Observable<ServiceActivityList> {
    let params = new HttpParams().set('page', '0').set('pageSize', '30');
    for (const id of serviceLogIds) {
      params = params.append('filter', `serviceLogId[equals]=${id}`);
    }
    return this.api.get<ServiceActivityList>(this.url, { params }, this.apiVersion);
  }

  setApiVersion(apiVersion?: string) {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }

  setUrl(url?: string) {
    this.url = url ?? this.url;
    return this;
  }
}
