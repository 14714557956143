<advance-interactive-box
  class="margin-20-top"
  *ngIf="!loading && (relatedServiceActivities?.length ?? 0) > 0"
  (clickIcon)="toggleOverlay()">
  <span class="gea-font-heading-16" data-cy="title">{{ 'TICKETS.DETAIL.RELATED-SERVICE-LOGS.TITLE' | translate }}</span>
  <span class="gea-font-subline-16" data-cy="subtitle">
    {{ relatedServiceActivities?.length }}
    {{
      (relatedServiceActivities?.length == 1
        ? 'TICKETS.DETAIL.RELATED-SERVICE-LOGS.SINGLE-SUBTITLE'
        : 'TICKETS.DETAIL.RELATED-SERVICE-LOGS.SUBTITLE'
      ) | translate
    }}
  </span>

  <div class="related-service-logs-overlay" *ngIf="showOverlay">
    <div class="header">
      <span class="gea-font-heading-16" data-cy="title">
        {{ 'TICKETS.DETAIL.RELATED-SERVICE-LOGS.TITLE' | translate }}
      </span>
      <geaui-icon-v2 [file]="'16px_close'" [clickable]="true" (click)="toggleOverlay()" color="black"> </geaui-icon-v2>
    </div>

    <div class="related-service-logs-container">
      <advance-interactive-box *ngFor="let activity of relatedServiceActivities" (clickIcon)="handleActivityClick(activity)">
        <span class="gea-font-heading-16" [pTooltip]="activity.title" data-cy="title" geauiTooltipOnEllipsisV2>
          {{ activity.title }}
        </span>
        <span class="gea-font-body-16" data-cy="title">
          {{ activity.plannedStartDate | userTimezoneDate: 'medium' }}
        </span>
      </advance-interactive-box>
    </div>
  </div>
</advance-interactive-box>

<ngx-skeleton-loader *ngIf="loading" [theme]="{ height: '83px', margin: '20px 0 0 0' }" count="1" appearance="line">
</ngx-skeleton-loader>
