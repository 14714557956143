import { Features } from '../models/features-configuration.model';
import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { FeatureFlagConfigurationState, FeatureFlagService } from '@gea/digital-ui-lib';
import { filter, map } from 'rxjs';

export function checkFeatureFlagGuard(neededFeatureFlags: string[]): CanActivateFn {
  return () => {
    const router = inject(Router);
    const store = inject(Store);
    const featureFlagService = inject(FeatureFlagService);

    featureFlagService.getFeatureFlagConfigurationForStore();

    return store.select(FeatureFlagConfigurationState.features).pipe(
      filter((features) => !!features),
      map((features: Features) => {
        const hasFeatureFlags = neededFeatureFlags.every((featureFlag) => featureFlag in features);
        if (hasFeatureFlags) {
          return true;
        } else {
          void router.navigate(['/']);
          return false;
        }
      })
    );
  };
}
