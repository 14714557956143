import { Inject, Injectable, Injector } from '@angular/core';
import { ApiService } from '@gea/digital-ui-lib';

@Injectable({
  providedIn: 'root',
})
export class ServiceLogApiService extends ApiService {
  constructor(
    @Inject('serviceLogBaseUrl') protected override baseUrl: string,
    protected override injector: Injector,
  ) {
    super(injector, baseUrl);
  }
}
